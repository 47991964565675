import { getCookie } from "~/utils/cookies";

export async function logout(suppressRedirect) {
  try {
    const auth2 = window.gapi.auth2.getAuthInstance();
    await auth2.signOut();
  } catch (e) {}

  invalidateRelayStore();
  const response = await fetch("/api/v1/auth/logout/", {
    method: "POST",
    headers: { "X-CSRFToken": getCsrfToken() },
  });
  await response.json();
  if (!suppressRedirect) {
    window.location = "/login";
  }
}

export function getCsrfToken() {
  // note, we can remove call to getCookie("csrftoken") fallback after initial release +
  return (
    document.querySelector("[name=csrfmiddlewaretoken]").value ||
    getCookie("csrftoken")
  );
}

export function refreshCsrfToken() {
  // needs to be synchronous in order to update the csrf token before the next request happens
  const xhr = new XMLHttpRequest();
  xhr.open("GET", "/api/v1/auth/csrf/", false); // false makes the call synchronous
  xhr.withCredentials = true; // include csrf cookies (csrf protected)
  xhr.send(null);
  if (xhr.status === 200) {
    const data = JSON.parse(xhr.responseText);
    syncHttpCsrf(data.csrf_token);
    return xhr.status;
  } else {
    console.error("Failed to refresh CSRF token", xhr.status, xhr.statusText);
    return xhr.status;
  }
}

export function syncHttpCsrf(csrfToken) {
  const csrf_http = document.querySelector("[name=csrfmiddlewaretoken]").value;
  if (csrfToken && csrf_http != csrfToken) {
    document.querySelector("[name=csrfmiddlewaretoken]").value = csrfToken;
  }
}
