import React, { Suspense } from "react";

import { DelayedFallback } from "~/components/DelayedFallback";

/**
 * Wrap the Component to suspend with the fallback. Please note that you'll likely want to use `withDelayedSuspenseFallback` instead.
 */
export function withSuspenseFallback<P extends {}>(
  Component: React.ComponentType<P>,
  fallback: React.ReactNode,
) {
  return (props: React.ComponentProps<typeof Component>) => (
    <Suspense fallback={fallback}>
      <Component {...props} />
    </Suspense>
  );
}

/**
 * Wrap the Component to suspend with the fallback.
 *
 * This is best used for a Suspense fallback to avoid flickering and a poor UX.
 * 
 * @example
 * 
 * export const ContractPageHeaderWithFallback = withDelayedSuspenseFallback(
   ContractPageHeader,
   <ContractPageHeaderSkeleton />,
 );
 */

export function withDelayedSuspenseFallback<P extends {}>(
  Component: React.ComponentType<P>,
  fallback: React.ReactNode,
  DelayedFallbackProps?: Omit<
    React.ComponentProps<typeof DelayedFallback>,
    "fallback"
  >,
) {
  return (props: React.ComponentProps<typeof Component>) => (
    <Suspense
      fallback={
        <DelayedFallback fallback={fallback} {...DelayedFallbackProps} />
      }
    >
      <Component {...props} />
    </Suspense>
  );
}
