import React from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  Paper,
  Stack,
  Typography,
  type SxProps,
  type Theme,
} from "@mui/material";
import { ContractPageHeaderActions } from "./ContractPageHeaderActions";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { ContractFolderPath } from "./ContractFolderPath";

type ContractPageHeaderLayoutProps = {
  title: React.ReactNode;
  folderPath: React.ReactNode;
};

export function ContractPageHeaderLayout(props: ContractPageHeaderLayoutProps) {
  const [isSummaryOpen, setIsSummaryOpen] = React.useState(false);

  const handleSummaryClick = () => {
    setIsSummaryOpen((value) => !value);
  };

  function SummaryButton({ sx }: { sx?: SxProps<Theme> }) {
    return (
      <Button
        variant="text"
        endIcon={isSummaryOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        onClick={handleSummaryClick}
        sx={[
          { flexGrow: 0, textTransform: "none" },
          ...(Array.isArray(sx) ? [...sx] : [sx]),
        ]}
      >
        Summary
      </Button>
    );
  }

  function TitleAndActionsForSmallScreens({ sx }: { sx?: SxProps<Theme> }) {
    return (
      <Stack sx={sx}>
        {props.title}
        <Stack direction="row" sx={{ justifyContent: "space-between" }}>
          <SummaryButton sx={{ paddingLeft: 0 }} />
          <Stack
            direction="row"
            sx={{
              flexGrow: 1,
              containerName: "header-actions",
              containerType: "inline-size",
              justifyContent: "flex-end",
            }}
          >
            <ContractPageHeaderActions />
          </Stack>
        </Stack>
      </Stack>
    );
  }

  function TitleAndActionsForMediumScreens({ sx }: { sx?: SxProps<Theme> }) {
    return (
      <Box
        sx={[
          {
            containerType: "inline-size",
            justifyContent: "space-between",
            display: "grid",
            gridTemplateColumns: "minmax(300px, 1fr) auto auto",
          },
          ...(Array.isArray(sx) ? [...sx] : [sx]),
        ]}
      >
        {props.title}

        <SummaryButton sx={{ marginLeft: 5 }} />
        <ContractPageHeaderActions sx={{ marginLeft: 3 }} />
      </Box>
    );
  }

  return (
    <Stack
      sx={{
        paddingX: 8,
        paddingY: 4,
        containerType: "inline-size",
        borderBottom: "1px solid #E1E1E1",
        backgroundColor: "#FFF",
      }}
    >
      <Stack direction="row" sx={{ justifyContent: "space-between" }}>
        {props.folderPath}
      </Stack>

      <TitleAndActionsForSmallScreens
        sx={{
          display: "flex",
          "@container (min-width: 700px)": {
            display: "none",
          },
        }}
      />
      <TitleAndActionsForMediumScreens
        sx={{
          display: "none",
          "@container (min-width: 700px)": {
            display: "grid",
          },
        }}
      />
      {isSummaryOpen && (
        <Paper sx={{ height: "220px" }}>Summary Content Here</Paper>
      )}
    </Stack>
  );
}
