import React, { Suspense } from "react";
import { graphql } from "relay-runtime";
import { usePreloadedQuery, type PreloadedQuery } from "react-relay";
import type { ContractPageHeader_contractQuery as TContractPageHeader_contractQuery } from "./__generated__/ContractPageHeader_contractQuery.graphql";
import { ContractPageHeaderLayout } from "./ContractPageHeaderLayout";
import { ContractTitle, ContractTitleSkeleton } from "./ContractTitle";
import { DelayedFallback } from "~/components/DelayedFallback";
import {
  ContractFolderPath,
  ContractFolderPathSkeleton,
} from "./ContractFolderPath";
import { withDelayedSuspenseFallback } from "~/utils/withSuspenseFallback";

type ContractPageHeaderProps = {
  contractPageHeaderQueryRef: PreloadedQuery<
    TContractPageHeader_contractQuery,
    {}
  >;
};

export function ContractPageHeader(props: ContractPageHeaderProps) {
  const queryData = usePreloadedQuery(
    ContractPageHeader_contractQuery,
    props.contractPageHeaderQueryRef,
  );
  const contract = queryData.contract;

  return (
    <ContractPageHeaderLayout
      title={<ContractTitle title={contract?.title ?? ""} />}
      folderPath={<ContractFolderPath path={["Sales Agreements", "Oregon"]} />}
    />
  );
}

function ContractPageHeaderSkeleton() {
  return (
    <ContractPageHeaderLayout
      title={<ContractTitleSkeleton />}
      folderPath={<ContractFolderPathSkeleton />}
    />
  );
}

export const ContractPageHeaderWithFallback = withDelayedSuspenseFallback(
  ContractPageHeader,
  <ContractPageHeaderSkeleton />,
);

export const ContractPageHeader_contractQuery = graphql`
  query ContractPageHeader_contractQuery($uuid: String!) {
    contract(uuid: $uuid) {
      title
    }
  }
`;

export type { TContractPageHeader_contractQuery };
