import React, { Suspense } from "react";
import { ContractPageLayout } from "./layout/ContractPageLayout";
import { Outlet, useLoaderData } from "react-router";
import { graphql } from "relay-runtime";
import { loadQuery, usePreloadedQuery } from "react-relay";
import environment from "~/RelayEnvironment";
import {
  ContractPageHeader_contractQuery,
  type TContractPageHeader_contractQuery,
} from "./layout/ContractPageHeader";

export function clientLoader(args: any) {
  const contractPageHeaderQueryRef =
    loadQuery<TContractPageHeader_contractQuery>(
      environment,
      ContractPageHeader_contractQuery,
      {
        uuid: args.params.uuid,
      },
    );
  return { contractPageHeaderQueryRef };
}

export function Layout() {
  const { contractPageHeaderQueryRef } = useLoaderData<typeof clientLoader>();

  return (
    <ContractPageLayout contractPageHeaderQueryRef={contractPageHeaderQueryRef}>
      <Outlet />
    </ContractPageLayout>
  );
}

export function LayoutWithFallback() {
  // we don't expect a long load time for the layout so avoid flashing a spinner
  return (
    <Suspense fallback={<div></div>}>
      <Layout />
    </Suspense>
  );
}
