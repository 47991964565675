import Dialog from "@mui/material/Dialog";
import React, { Suspense } from "react";
import {
  graphql,
  useFragment,
  usePreloadedQuery,
  type PreloadedQuery,
} from "react-relay";
import type { Maybe } from "~/utils/types";
import {
  UpdateContractFromTemplateDialogContent,
  UpdateContractFromTemplateDialogContentSkeleton,
} from "./UpdateContractFromTemplateDialogContent";
import type { UpdateContractFromTemplateDialog_Query } from "./__generated__/UpdateContractFromTemplateDialog_Query.graphql";
import type { UpdateContractFromTemplateDialog_contractFragment$data } from "./__generated__/UpdateContractFromTemplateDialog_contractFragment.graphql";
import type { ContractFormsContract_Query } from "../../contracts/queries/__generated__/ContractFormsContract_Query.graphql";

export type UpdateContractFromTemplateDialogProps = {
  contractFormsContractQueryRef: Maybe<
    PreloadedQuery<ContractFormsContract_Query>
  >;
  queryRef: Maybe<PreloadedQuery<UpdateContractFromTemplateDialog_Query>>;
  mode: "CREATE_FILE" | "REPLACE_FILE";
  open: boolean;
  handleClose: () => void;
};

export function UpdateContractFromTemplateDialog(
  props: UpdateContractFromTemplateDialogProps,
) {
  return (
    <Dialog
      open={props.open}
      onClose={() => {
        props.handleClose();
      }}
      fullWidth={true}
      maxWidth="md"
      PaperProps={{
        sx: {
          height: "100%",
        },
      }}
      data-testid="add-contract-file-from-template-dialog-root"
    >
      <Suspense
        fallback={
          <UpdateContractFromTemplateDialogContentSkeleton mode={props.mode} />
        }
      >
        {props.queryRef && props.contractFormsContractQueryRef && (
          <UpdateContractFromTemplateDialogContentContainer
            mode={props.mode}
            contractFormsContractQueryRef={props.contractFormsContractQueryRef}
            queryRef={props.queryRef}
            handleClose={props.handleClose}
          />
        )}
      </Suspense>
    </Dialog>
  );
}

type UpdateContractFromTemplateDialogContentContainerProps = {
  contractFormsContractQueryRef: PreloadedQuery<ContractFormsContract_Query>;
  queryRef: PreloadedQuery<UpdateContractFromTemplateDialog_Query>;
  mode: "CREATE_FILE" | "REPLACE_FILE";
  handleClose: () => void;
};

function UpdateContractFromTemplateDialogContentContainer(
  props: UpdateContractFromTemplateDialogContentContainerProps,
) {
  const contractQueryData = usePreloadedQuery(
    // @ts-expect-error global.js query
    CONTRACT_FORMS_CONTRACT_QUERY,
    props.contractFormsContractQueryRef,
  );
  const contract = useFragment(
    CONTRACT_FRAGMENT,
    contractQueryData.contract,
  ) as UpdateContractFromTemplateDialog_contractFragment$data;
  const rootData = usePreloadedQuery(
    UPDATE_CONTRACT_FROM_TEMPLATE_DIALOG_QUERY,
    props.queryRef,
  );

  return (
    <UpdateContractFromTemplateDialogContent
      mode={props.mode}
      contractFragRef={contract}
      rootQueryFragRef={rootData}
      handleClose={props.handleClose}
    />
  );
}

export const UPDATE_CONTRACT_FROM_TEMPLATE_DIALOG_QUERY = graphql`
  query UpdateContractFromTemplateDialog_Query {
    ...UpdateContractFromTemplateDialogContent_rootQueryFragment
  }
`;

const CONTRACT_FRAGMENT = graphql`
  fragment UpdateContractFromTemplateDialog_contractFragment on ContractType {
    ...UpdateContractFromTemplateDialogContent_contractFragment
  }
`;
