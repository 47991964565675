import React from "react";
import {
  Box,
  Button,
  Fab,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  type FabProps,
  type SxProps,
  type Theme,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AbcIcon from "@mui/icons-material/Abc";
import ArrowOutwardOutlinedIcon from "@mui/icons-material/ArrowOutwardOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

type ContractPageHeaderActionsProps = {
  sx?: SxProps<Theme>;
};

export function ContractPageHeaderActions(
  props: ContractPageHeaderActionsProps,
) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack direction="row" sx={props.sx}>
      <ContractPageHeaderActionButton
        aria-label="open contract file"
        icon={<ArrowOutwardOutlinedIcon />}
      />

      <ContractPageHeaderActionButton
        aria-label="send contract file"
        icon={<SendOutlinedIcon />}
        sx={{
          display: "none",
          "@container (min-width: 250px)": { display: "block" },
        }}
      />

      <ContractPageHeaderActionButton
        aria-label="save contract file"
        icon={<SaveOutlinedIcon />}
        sx={{
          display: "none",
          "@container (min-width: 250px)": { display: "block" },
        }}
      />

      <ContractPageHeaderActionButton
        size="small"
        aria-label="more actions"
        id="more-actions-button"
        aria-controls={open ? "more actions" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        icon={<MoreHorizIcon />}
      />

      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "more-actions-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          sx={{
            display: "none",
            "@container (max-width: 250px)": { display: "flex" },
          }}
        >
          <SendOutlinedIcon />
          Share
        </MenuItem>
        <MenuItem
          sx={{
            display: "none",
            "@container (max-width: 250px)": { display: "flex" },
          }}
        >
          <SaveOutlinedIcon />
          Save
        </MenuItem>
      </Menu>
    </Stack>
  );
}

type ContractPageHeaderActionButtonProp = FabProps & {
  icon: React.ReactNode;
} & Required<Pick<FabProps, "aria-label">>;

function ContractPageHeaderActionButton(
  props: ContractPageHeaderActionButtonProp,
) {
  const { icon, sx, ...restProps } = props;
  return (
    <IconButton size="small" sx={sx} {...restProps}>
      <Paper
        sx={{
          padding: 2,
          borderRadius: "50%",
          color: "primary.main",
        }}
      >
        {icon}
      </Paper>
    </IconButton>
  );
}
