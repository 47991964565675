import React from "react";
import { Route } from "react-router";
import * as layout from "./layout";
import * as properties from "./properties";
import * as comments from "./comments";
import * as relatedDocuments from "./related-documents";
import * as aissistant from "./aissistant";
import * as approve from "./approve";
import * as esign from "./esign";

export const routes = (
  <>
    <Route
      path="v4/contracts/:uuid"
      element={<layout.LayoutWithFallback />}
      loader={layout.clientLoader}
    >
      <Route
        path="properties"
        element={<properties.PropertiesWithFallback />}
        loader={properties.clientLoader}
      />
      <Route
        path="comments"
        element={<comments.CommentsWithFallback />}
        loader={comments.clientLoader}
      />
      <Route
        path="related-documents"
        element={<relatedDocuments.RelatedDocumentsWithFallback />}
        loader={relatedDocuments.clientLoader}
      />
      <Route
        path="aissistant"
        element={<aissistant.AIssistantWithFallback />}
        loader={aissistant.clientLoader}
      />
      <Route
        path="approve"
        element={<approve.ApproveWithFallback />}
        loader={approve.clientLoader}
      />
      <Route
        path="esign"
        element={<esign.ESignWithFallback />}
        loader={esign.clientLoader}
      />
    </Route>
  </>
);
