import React from "react";
import { type PreloadedQuery } from "react-relay";
import { Box, Stack } from "@mui/material";
import { ContractPageBottomNavigation } from "./ContractPageBottomNavigation";
import { ContractPageDrawerNavigation } from "./ContractPageDrawerNavigation";
import {
  ContractPageHeader,
  ContractPageHeaderWithFallback,
  type TContractPageHeader_contractQuery,
} from "./ContractPageHeader";

type ContractPageLayoutProps = {
  children: React.ReactNode;
  contractPageHeaderQueryRef: PreloadedQuery<
    TContractPageHeader_contractQuery,
    {}
  >;
};

export function ContractPageLayout(props: ContractPageLayoutProps) {
  return (
    <Stack
      sx={{
        position: "relative",
        height: "100%",
        width: "100%",
        backgroundColor: "#F7F8FB",
      }}
    >
      <ContractPageHeaderWithFallback
        contractPageHeaderQueryRef={props.contractPageHeaderQueryRef}
      />
      <Stack
        direction="row"
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <ContractPageDrawerNavigation
          sx={{ display: { xs: "none", sm: "flex" } }}
        />
        <Box
          sx={{
            padding: 5,
            backgroundColor: "#F7F8FB",
            width: "100%",
            height: "100%",
          }}
        >
          {props.children}
        </Box>
      </Stack>
      <ContractPageBottomNavigation sx={{ display: { sm: "none" } }} />
    </Stack>
  );
}
