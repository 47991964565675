import { useMemo } from "react";
import { useAuthentication } from "./components/Authentication";

export const useFeatures = () => {
  const { organization } = useAuthentication();
  return useMemo(() => {
    const has = (feature: string) =>
      !!organization?.billing.features.includes(feature);
    return {
      emailDocuments: has("email_documents"),
      addingAdditionalUsers: has("adding_additional_users"),
      emailDocumentsToAccount: has("email_documents_toaccount"),
      logoBranded: has("logo_branded"),
      handleAmendments: has("handle_amendments"),
      handleRelatedDocs: has("handle_related_docs"),
      customDocumentTypes: has("custom_document_types"),
      limitUserAccess: has("limit_user_access"),
      limitUserAccessByTags: has("limit_user_access_by_tags"),
      customContractFields: has("custom_contract_fields"),
      additionalSecurity: has("additional_security"),
      documentTagging: has("document_tagging"),
      docusignListen: has("docusign_listen"),
      singleSignOn: has("single_sign_on"),
      docusignSend: has("docusign_send"),
      salesforceListen: has("salesforce_listen"),
      publicApiAccess: has("public_api_access"),
      zapierListen: has("zapier_listen"),
      ipWhitelisting: has("ip_whitelisting"),
      aissistant: has("aissistant"),
      archive: has("archive"),
      redlines: has("redlines"),
      subfolders: has("subfolders"),
      permissionGroups: has("permission_groups"),
      complexPermissions: has("complex_permissions"),
      approvals: has("approvals"),
      standaloneEsign: has("stand-alone-e-sign"),
      intakeForms: has("intake-forms"),
      templates: has("templates"),
      commenting: has("commenting"),
    } as const;
  }, [organization?.billing.features]);
};
